import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Field, reduxForm } from 'redux-form'
import { NavLink } from 'react-router-dom'
import moment from 'moment'
import { Element } from 'react-scroll'
import FileInputField from '../../../components/FileInputField'
import DatePickerFormComponent from '../../../components/redux-form/DatePickerFormComponent'
import { MenuItem, DropdownButton } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import * as solidIcons from '@fortawesome/free-solid-svg-icons'
import { getVehicleIcon } from 'libs/vehicles'
import NewVehicleModal from 'components/Vehicles/NewVehicleModal'
import DisableForm from './DisableForm'
import { areObligatoryDocsSelected, getDocumentColor, getMarkExpirationDate, MARK_TYPE_ELECTRIC, MARK_TYPE_FAMILY, MARK_TYPE_GREEN, MARK_TYPE_HYBRID } from 'libs/marks'
import { MARK_ENABLE_START_DATE } from 'libs/settings'
import SostaOnlineApiService from 'services/SostaOnlineApiService'
import { format } from 'date-fns';

class MarkRequest extends Component {

  static propTypes = {
    user: PropTypes.object,
    disableTitle: PropTypes.string,
    onMarkTypeSelected: PropTypes.func.isRequired,
    selectedMarkType: PropTypes.object,
    selectedVehicles: PropTypes.arrayOf(PropTypes.object),
    settings: PropTypes.object,
    documents: PropTypes.array,
    errors: PropTypes.string,
    onVehicleSelected: PropTypes.func.isRequired,
    loading: PropTypes.bool,
    onVehicleAdd: PropTypes.func,
    onVehicleRemove: PropTypes.func,
    price: PropTypes.object,
    onSubmit: PropTypes.func.isRequired,
    markRules: PropTypes.object,
    submitting: PropTypes.bool,
    markTypes: PropTypes.arrayOf(PropTypes.object),
    selectedDocuments: PropTypes.arrayOf(PropTypes.object),
    onDocumentAdd: PropTypes.func,
    onDocumentRemove: PropTypes.func,
    onDocumentChange: PropTypes.func,
    onDateChange: PropTypes.func,
    handleSubmit: PropTypes.func,
    selectedStartDate: PropTypes.string,
    onCloseFileManager: PropTypes.func
  }

  /**
   * --------------------------------------------------------------------------
   * @param {*} props 
   */
  constructor(props) {
    super(props)

    this.state = {
      showNewVehicleModal: false,
      showDisableModel: false,
      title: '',
      prices: []
    }


    this.onCloseNewVehicleModal = this.onCloseNewVehicleModal.bind(this)
    this.showNewVehicleModal = this.showNewVehicleModal.bind(this)
    this.showDisableModel = this.showDisableModel.bind(this)
    this.onVehicleSelected = this.onVehicleSelected.bind(this)
    this.onAddVehicle = this.onAddVehicle.bind(this)
    this.getMarkTypePrices = this.getMarkTypePrices.bind(this)

  }

  /**
   * --------------------------------------------------------------------------
   * 
   */
  async componentDidMount() {
    this.setState({
      showNewVehicleModal: false,
      showDisableModel: false,
      prices: await this.getMarkTypePrices(),
    })
  }

  /**
   * --------------------------------------------------------------------------
   * @param {*} prevProps 
   */
  async componentDidUpdate(prevProps) {
    if (this.props.selectedStartDate != prevProps.selectedStartDate) {
      this.setState({
        prices: await this.getMarkTypePrices()
      })
    }

  }

  /**
   * --------------------------------------------------------------------------
   * 
   */
  onCloseNewVehicleModal() {
    this.setState({ showNewVehicleModal: false })
  }

  /**
   * --------------------------------------------------------------------------
   * 
   */
  showNewVehicleModal() {
    this.setState({ showNewVehicleModal: true })
  }

  /**
   * --------------------------------------------------------------------------
   * @param {*} vehicleId 
   */
  onVehicleSelected(vehicleId) {

    if (vehicleId != null && this.props.onVehicleSelected) {
      this.props.onVehicleSelected(vehicleId)
    }
    else {
      this.showNewVehicleModal()
    }

  }

  /**
   * --------------------------------------------------------------------------
   * @param {*} vehicle 
   */
  onAddVehicle(vehicle) {
    if (this.props.onVehicleAdd)
      this.props.onVehicleAdd(vehicle)

  }

  /**
   * --------------------------------------------------------------------------
   * @returns 
   */
  areSelectedVehicles() {
    var filter = this.props.selectedVehicles.filter(item => (item.id != null || item.type === 'new'))
    return filter.length > 0
  }

  /**
   * --------------------------------------------------------------------------
   * @returns 
   */
  getUserVehicles() {
    var result = []
    var vehicles = []

    const {
      user,
      selectedMarkType,
      selectedVehicles
    } = this.props

    if (user && user.vehicles) {

      vehicles = user.vehicles

      if (selectedMarkType) {
        if (selectedMarkType.type === MARK_TYPE_HYBRID) {
          vehicles = vehicles.filter(item => item.VehiclePowerSupply.hybrid)
        }
        else if (selectedMarkType.type === MARK_TYPE_ELECTRIC) {
          vehicles = vehicles.filter(item => item.VehiclePowerSupply.electric)
        }
        else if (selectedMarkType.type === MARK_TYPE_GREEN) {
          vehicles = vehicles.filter(item => (item.VehiclePowerSupply.hybrid || item.VehiclePowerSupply.electric))
        }
      }

      vehicles.map(v => {

        var find = selectedVehicles.filter(sv => sv.id === v.id)

        if (find.length <= 0)
          result.push(v)

      })

    }

    return result

  }

  /**
   * --------------------------------------------------------------------------
   */
  showSelectStartDateStep(selectedMarkType) {
    var settings = this.props.settings

    return selectedMarkType && (selectedMarkType.type === MARK_TYPE_FAMILY || settings[MARK_ENABLE_START_DATE].value == "1")
  }

  /**
   * --------------------------------------------------------------------------
   * @returns 
   */
  showSecondStep(selectedMarkType, markRules, selectedStartDate) {
    var settings = this.props.settings
    var duration = null

    if (selectedMarkType == null || markRules == null) {
      return false
    }

    if (selectedMarkType != null && selectedMarkType.type == MARK_TYPE_FAMILY) {
      var setting = settings[selectedMarkType.id]

      if (setting == null)
        return false

      duration = parseInt(setting.value)
    }

    return (
      !this.props.markLimitReached &&
      selectedMarkType &&
      markRules.id &&
      (
        (selectedMarkType.type !== MARK_TYPE_FAMILY && settings[MARK_ENABLE_START_DATE].value == "0") ||
        (selectedMarkType.type !== MARK_TYPE_FAMILY && settings[MARK_ENABLE_START_DATE].value == "1" && selectedStartDate != null) ||
        (selectedMarkType.type === MARK_TYPE_FAMILY && selectedStartDate != null && moment(selectedStartDate).add(duration, 'days').isAfter(moment(), 'days'))
      )
    )

  }

  /**
   * --------------------------------------------------------------------------
   * @param {*} selectedMarkType 
   * @param {*} markRules 
   * @returns 
   */
  showThirdStep(selectedMarkType, markRules, selectedStartDate) {
    return (
      this.showSecondStep(selectedMarkType, markRules, selectedStartDate) &&
      this.areSelectedVehicles() &&
      this.props.documents.length > 0
    )
  }

  /**
   * --------------------------------------------------------------------------
   * @returns 
   */
  showFinalStep(selectedMarkType, markRules, price, selectedStartDate) {
    return (
      this.showSecondStep(selectedMarkType, markRules, selectedStartDate) &&
      this.areSelectedVehicles() &&
      this.areObligatoryDocsSelected() &&
      price != null
    )
  }

  /**
   * --------------------------------------------------------------------------
   * @returns 
   */
  areObligatoryDocsSelected() {
    var {
      documents,
      selectedDocuments
    } = this.props

    return areObligatoryDocsSelected(documents, selectedDocuments)
  }

  /**
   * --------------------------------------------------------------------------
   * @param {*} document 
   * @returns 
   */
  getDocumentColor(document) {
    return getDocumentColor(document, this.props.selectedDocuments)
  }

  /**
   * --------------------------------------------------------------------------
   * 
   */
  async getMarkTypePrices() {

    var {
      user,
      markTypes,
      selectedStartDate
    } = this.props

    var prices = []

    for (let markTypeKey in markTypes) {

      var markType = markTypes[markTypeKey]

      prices[markType.id] = null

      if (markType.Prices != null && markType.Prices.length > 0) {
        var userCats = user.categories.filter((category, index) => category.resident == true)
        var markTypeCats = markType.Categories.filter((category, index) => category.resident == true)

        if (userCats != null && userCats.length > 0 && markTypeCats != null && markTypeCats.length > 0) {
          for (let key in markTypeCats) {

            for (let key1 in userCats) {

              if (markTypeCats[key].id == userCats[key1].id) {

                var selectedPrice = await SostaOnlineApiService.fetchMarkPrice(
                  user.id,
                  markType.id,
                  markTypeCats[key].id,
                  null,
                  null,
                  selectedStartDate
                )
                  .catch(err => {
                    console.log("ERROR:", err)
                  })

                if (selectedPrice != null && selectedPrice.data != null) {
                  prices[markType.id] = selectedPrice.data.price
                  break;
                }

              }
            }

            if (prices[markType.id] != null) {
              break
            }

          }
        }

      }
      else {
        prices[markType.id] = markType.price
      }

    }

    return prices

  }


  /**
   * --------------------------------------------------------------------------
   * 
   */
  checkSelectedStartDate() {

    var {
      selectedStartDate,
      selectedMarkType,
      settings
    } = this.props

    var msg = ""

    if (selectedMarkType.type == MARK_TYPE_FAMILY) {
      var duration = parseInt(settings[selectedMarkType.id].value, 10)
      if (moment(selectedStartDate).add(duration, 'days').isBefore(moment(), 'days')) {
        msg = "Errore! Il figlio ha già raggiunto un anno di età."
      }
    }
    else if (settings[MARK_ENABLE_START_DATE].value == "1" && moment(selectedStartDate).isBefore(moment())) {
      msg = "La data di validità deve essere successiva alla data odierna"
    }

    return msg
  }

  /**
   * --------------------------------------------------------------------------
   * @param {*} item 
   * @returns 
   */
  getMarkTypePriceDescription(item) {

    const {
      prices
    } = this.state

    if (prices[item.id] == null) {
      return (<h3 className="title3">Non Disponibile</h3>)
    }

    else if (!item.free || parseFloat(prices[item.id]) > 0) {
      return (
        <h3 className="title3">
          {parseFloat(prices[item.id]).toFixed(2) + " €"}
        </h3>)
    }

    else {
      return (<h3>Gratuito</h3>)
    }
  }

  /**
   * --------------------------------------------------------------------------
   * @returns 
   */
  showDisableModel() {
    return (
      this.setState({ showDisableModel: true })
    )
  }

  /**
   * --------------------------------------------------------------------------
   * @param {*} item 
   * @returns 
   */
  async takeDisableTitle(item) {
    let {
      permanentMark
    } = this.state

    if (item.typeName == "DOMANDA DI RILASCIO/RINNOVO CONTRASSEGNO PERMANENTE") {
      return (
        this.permanentMark = item.typeLabel
      )
    } else if (item.typeName == "DOMANDA DI RILASCIO/RINNOVO CONTRASSEGNO TEMPORANEO") {
      return (
        this.permanentMark = item.typeLabel
      )
    }
  }

  /**
 * --------------------------------------------------------------------------
 * @param {*} item 
 * @returns 
 */
  async getTitle(item) {
    console.log(item);
    try {

      this.title = await this.takeDisableTitle(item);
      console.log('il titolo', this.title);
      return;
    } catch (e) {
      console.log("Si è verificato un errore!");
    }
  }

  /**
 * --------------------------------------------------------------------------
 * @returns 
 */
  closeDisableModel() {
    return (
      this.setState({ showDisableModel: false })
    )
  }

  /**
   * --------------------------------------------------------------------------
   * @returns 
   */
  render() {
    const {
      markTypes,
      loading,
      settings,
      errors,
      documents,
      onMarkTypeSelected,
      selectedMarkType,
      selectedVehicles,
      markRules,
      onVehicleRemove,
      price,
      submitting,
      selectedDocuments,
      onDocumentAdd,
      onDocumentRemove,
      onDocumentChange,
      onDateChange,
      selectedStartDate,
      onCloseFileManager,
      user,

    } = this.props
    //console.log('laltro titolo');

    const {
      showNewVehicleModal,
      prices
    } = this.state
    let {
      showDisableModel,

    } = this.state

    if (loading) {
      return (
        <div>Caricamento in corso...</div>
      )
    }

    if (user == null || user.userConfirmed == null || !user.userConfirmed)
      return (
        <div className="col-md-8 col-md-push-2">
          <h5 className="title-danger text-center" style={{ minHeight: "40em" }}>
            <strong>Attenzione! </strong>
            <br />
            <br />
            Non hai ancora confermato la tua mail,{' '}
            il download e l'acquisto dei permessi sarà disponibile solo a mail confermata
          </h5>
        </div>
      )

    // TODO gestire l'iscrizione a categorie non attive per l'utente
    return (
      <form onSubmit={this.props.handleSubmit(values => {
        this.props.onSubmit({ ...values })
      })}>
        <div className="col-md-10 col-md-push-1">
          <div className="panel panel-default panel-border">
            <div className="panel-body">
              <NavLink to="/user-area" className="btn mainBtn" style={{ margin: 20 }}>Torna indietro</NavLink>
              <h2 className="title title-special">Richiedi Permesso</h2>
              <p>Da qui puoi richiedere un nuovo permesso per la sosta nel Comune di {process.env.REACT_APP_CITYL}<br /></p>
              {/* SEZIONE PERMESSI ABILITATI PER L'UTENTE */}
              <div className="box">
                <fieldset>

                  {/* INTESTAZIONE PERMESSI ABILITATI PER L'UTENTE */}
                  <div className="titolo-categoria">
                    <legend className="text-left">Tipologia Permesso</legend>
                    <p>Seleziona il tipo di permesso di cui necessiti tra quelli disponibili per il tuo utente</p>
                  </div>

                  {/* ELENCO PERMESSI ABILITATI PER L'UTENTE */}
                  <div className="row">
                    {markTypes.map((item, i) => {

                      let activeClass = ''
                      const multipleAreas = item.Areas.length > 1

                      if (selectedMarkType && selectedMarkType.id === item.id) {
                        activeClass = ' active'
                      }

                      return (
                        <div key={i} className="col-sm-6 col-md-6 col-lg-6 col-xl-6">
                          <div
                            onClick={() => onMarkTypeSelected(item)}
                            className={'panel panel-default park-ticket-panel panel-border' + activeClass}>
                            <div className="panel-body-dark text-center" >
                              <h4 style={{ color: item.color == null || item.color.toUpperCase() == "#FFFFFF" ? "#5a5a5a" : item.color }}><strong>{item.label}</strong></h4>
                              {this.getMarkTypePriceDescription(item)}
                              <div className="panel-body-dark flex-grow panel-2">
                                Valido nell{multipleAreas ? 'e' : 'a'} zon{multipleAreas ? 'e' : 'a'}:{' '}
                                {item.Areas.map((areaItem, index) => (
                                  <span key={index}>
                                    <strong>{areaItem.code}</strong>
                                    {index === item.Areas.length - 1 ? '' : ', '}
                                  </span>
                                ))}
                                <br />
                                {item.type === MARK_TYPE_FAMILY ?
                                  (
                                    <span>Valido fino al: <strong>primo anno del figlio</strong></span>
                                  ) :
                                  (getMarkExpirationDate(settings, item, null) &&
                                    <span>Valido fino al:{' ' + getMarkExpirationDate(settings, item, null).format("L")}</span>
                                  )
                                }
                              </div>
                            </div>
                          </div>
                        </div>
                      )
                    })}
                    <Field name="markType" component="input" type="hidden" />
                  </div>
                </fieldset>
              </div>

              {/* SEZIONE RICHIESTA CON SELEZIONE DATA DI INIZIO VALIDITA' */}
              {this.showSelectStartDateStep(selectedMarkType) && (
                <div className="box">
                  <Element name="child-borndate" />
                  <div className="titolo-categoria">
                    {selectedMarkType.type === MARK_TYPE_FAMILY ?
                      <React.Fragment>
                        <legend className="text-left">Data di nascita del figlio</legend>
                        <p>Il contrassegno Family è valido fino al compimento di <strong>1 anno</strong>.</p>
                      </React.Fragment> :
                      <React.Fragment>
                        <legend className="text-left">Data</legend>
                      </React.Fragment>
                    }
                  </div>
                  <div className="row">
                    <div className="col-md-4 text-left">
                      {selectedMarkType.type === MARK_TYPE_FAMILY ?
                        <label>Data di Nascita del figlio</label> :
                        <label>Data di inizio validità permesso</label>
                      }
                      <Field
                        placeholderText="GG/MM/AAAA"
                        component={DatePickerFormComponent}
                        name="selectedStartDate"
                        className="mainField"
                        onDateChange={onDateChange}
                        selected={selectedStartDate}
                        required
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-12">
                      <div className="text-danger">
                        {this.checkSelectedStartDate()}
                      </div>
                    </div>
                  </div>
                </div>
              )}

              {/* SEZIONE VEICOLI */}
              <Element name="step-two" />
              {this.showSecondStep(selectedMarkType, markRules, selectedStartDate) &&
                <div className="box">
                  <fieldset>
                    <div className="titolo-categoria">

                      <legend className="text-left">Veicoli</legend>

                      <h4>
                        Questo permesso è utilizzabile su un massimo di {markRules.vehicleLimit} veicol{markRules.vehicleLimit > 1 ? 'i' : 'o'}
                      </h4>

                      <div className="row">
                        <div className="col-md-12 text-left">

                          <DropdownButton
                            id="AddVehicle"
                            title="SCEGLI VEICOLO"
                            className="mainBtn"
                            dropup
                            onSelect={(vehicleId) => { this.onVehicleSelected(vehicleId) }}
                          >
                            <MenuItem eventKey={null} style={{ minWidth: "400px", padding: "0px", margin: "0" }}>
                              <h4 style={{ margin: "5px", padding: "3px" }}>
                                <span><FontAwesomeIcon size="1x" className="sectionIcons" icon={solidIcons.faPlusCircle} />&nbsp;&nbsp;</span>
                                <span><strong>Nuovo Veicolo</strong></span>
                              </h4>
                            </MenuItem>
                            {
                              this.getUserVehicles().map((item) =>
                                <MenuItem key={"select-vehicle-" + item.id} eventKey={item.id} style={{ minWidth: "400px", padding: "0px", margin: "0" }}>
                                  <h4 style={{ margin: "5px", padding: "3px" }}>
                                    <span>{getVehicleIcon(item.VehiclePowerSupply, "1x")}&nbsp;&nbsp;</span>
                                    <span><strong>{item.plate_number}&nbsp;&nbsp;</strong></span>
                                    <span>{item.VehiclePowerSupply.name}</span>
                                  </h4>
                                </MenuItem>
                              )
                            }
                          </DropdownButton>

                          <span className={selectedVehicles.length >= markRules.vehicleLimit ? 'text-danger' : 'text-primary'}>
                            <strong>{' Veicoli Utilizzati: ' + selectedVehicles.length} / {markRules.vehicleLimit}</strong>
                          </span>
                        </div>
                      </div>

                    </div>

                    {selectedVehicles.map((item, index) => {
                      return (
                        <div className="row row-list-body" key={'vehicle-' + index} >

                          <div className="col-sm-1 text-left cell-list-body ">
                            <div style={{ marginTop: 5, paddingLeft: 15 }}>{getVehicleIcon(item.VehiclePowerSupply, "2x")}</div>
                          </div>

                          <div className="col-sm-3 text-left cell-list-body ">
                            <div style={{ marginTop: 5 }}><strong>{item.plate_number}</strong></div>
                          </div>

                          <div className="col-sm-3 text-left cell-list-body">
                            <div style={{ marginTop: 5 }}>{item.VehiclePowerSupply.name}</div>
                          </div>

                          <div className="col-sm-4 text-left cell-list-body">
                            <div style={{ marginTop: 5 }}>{item.VehicleOwnershipType.name}</div>
                          </div>

                          <div className="col-sm-1 cell-list-body">
                            <div onClick={() => onVehicleRemove(index)} className="alertBtn">
                              <FontAwesomeIcon size="1x" icon={solidIcons.faTrash} />
                            </div>
                          </div>
                        </div>
                      )
                    })}
                  </fieldset>
                </div>
              }

              {/* SEZIONE CARICAMENTO DOCUMENTI */}
              <Element name="step-three" />
              {this.showThirdStep(selectedMarkType, markRules, selectedStartDate) &&
                <div className="box">
                  <fieldset>
                    <div className="titolo-categoria">
                      <legend className="text-left">Documenti</legend>
                      <span className="text-center col-md-12" >
                        {/* <!--h4 className="text-center col-md-12">La richiesta di questo permesso richiede l'upload dei seguenti documenti:</h4--> */}
                        <h4 className="text-center col-md-12">Elenco documentazione per il rilascio ( in ROSSO obbligatoria, in GIALLO facoltativa ):</h4>
                        {documents.map((item, index) => {
                          return (
                            item.typeLabel == "DOCUMENTO CON MARCA DA BOLLO" ? (
                              <p key={"doc-" + index} style={{ color: 'black', fontWeight: '500' }}>
                                <strong>N.B.: </strong>Per il documento <span style={{ color: 'red' }}>"DOCUMENTO CON MARCA DA BOLLO"</span>, scaricare il fac simile al seguente link, compilarlo e applicare la marca da bollo nell'apposito spazio. Dopodichè scannerizzare e allegare il documento alla richiesta di permesso.&nbsp;&nbsp;<a href="#">Clicca qui!!!</a>
                              </p>
                            ) : item.typeName == "DOMANDA DI RILASCIO/RINNOVO CONTRASSEGNO PERMANENTE" | item.typeName == "DOMANDA DI RILASCIO/RINNOVO CONTRASSEGNO TEMPORANEO" ? (
                              <p key={"doc-" + index} style={{ color: 'black', fontWeight: '500' }}>
                                <strong>N.B.: </strong>Per allegare il documento <span style={{ color: 'red' }}>"{item.typeLabel}'"</span>, cliccare il pulsante qui sotto, compilare il modello e scaricarlo in formato PDF. Infine allegarlo alla richiesta di permesso.<br></br>
                                <button type="button" className="mainBtn" onBlur={this.getTitle(item)} onClick={this.showDisableModel}>Clicca qui!!!</button>
                              </p>
                            ) : ('')
                          )
                        })}
                        <strong className="text-center col-md-12">
                          <ul className="text-left">
                            {documents.map((item, index) => {

                              var color = this.getDocumentColor(item)

                              return (
                                <li key={"doc-" + index} style={{ 'color': color }} >
                                  {item.typeLabel} {item.mandatory ? "( obbligatorio )" : "( facoltativo )"}
                                </li>
                              )
                            })}
                          </ul>
                        </strong>
                      </span>
                      <p>I file caricati devono essere nel formato: png, jpeg, pdf.</p>
                    </div>

                    <div className="row">
                      <div className="col-md-12 text-left">
                        <a type="button" className="mainBtn" onClick={onDocumentAdd}>
                          Allega Documento
                        </a>
                      </div>
                    </div>

                    <br />

                    {selectedDocuments.map((item, index) => {

                      return (
                        <div className="form-group row row-list-body" key={'document-' + index + '-' + item.id}>

                          <div className="col-sm-6 text-left  cell-list-body " >
                            <div style={{ textOverflow: 'ellipsis', marginLeft: 15, paddingTop: 10 }}>
                              <strong>{item.DocumentType ? item.DocumentType.label : 'Seleziona un documento'}</strong>
                            </div>
                          </div>

                          <div className="col-sm-5 text-left cell-list-body" >
                            <Field
                              openFileManager={item.openFileManager}
                              onChange={(document) => { onDocumentChange(document, index) }}
                              onClose={onCloseFileManager}
                              name={'documentFile-' + index + '-' + item.id}
                              component={FileInputField}
                              required
                            />
                          </div>

                          <div className="col-sm-1 text-left cell-list-body">
                            <div onClick={() => onDocumentRemove(index)} className="alertBtn" >
                              <FontAwesomeIcon size="1x" icon={solidIcons.faTrash} />
                            </div>
                          </div>

                        </div>
                      )
                    })}
                  </fieldset>
                </div>
              }

              <div className={(showDisableModel === false ? 'hidden' : 'show')} style={{ display: 'flex', justifyContent: 'center' }}>
                <div style={{ position: 'absolute', top: 0, left: 0, right: 0, bottom: 0, backgroundColor: '#FFFFFF44', display: 'flex', alignItems: 'center', justifyContent: 'center', backdropFilter: 'blur(8px)', width: '100%', height: '100%' }}>
                  <DisableForm
                    user={user}
                    disableTitle={this.title}
                  />
                  <div style={{ position: 'absolute', zIndex: 101, top: 465, right: 170, fontSize: '25px' }}>
                    <button type="button" onClick={() => this.closeDisableModel()} className="glyphicon glyphicon-remove chiudi-btn"></button>
                  </div>
                </div>
              </div>

              {/* SEZIONE RIEPILOGO E INVIO RICHIESTA */}
              <Element name="step-final" />
              {this.showFinalStep(selectedMarkType, markRules, price, selectedStartDate) &&
                <div className="box">
                  <fieldset>
                    <div className="titolo-categoria">
                      <legend className="text-left">Riepilogo</legend>
                    </div>

                    <div className="col-md-6">
                      <div className="card-marks">
                        <div className="caption" style={{ padding: 10 }}>
                          <h5>
                            <strong>{selectedMarkType.label}</strong>
                          </h5>
                          <h5>
                            Valido nell{selectedMarkType.Areas.length > 1 ? 'e' : 'a'} zon{selectedMarkType.Areas.length > 1 ? 'e' : 'a'}:{' '}
                            {selectedMarkType.Areas.map((areaItem, index) => (
                              <span key={index}>
                                <strong>{areaItem.code}</strong>
                                {index === selectedMarkType.Areas.length - 1 ? '' : ', '}
                              </span>
                            ))}
                          </h5>
                          {selectedMarkType.Categories && selectedMarkType.Categories.length > 0 && selectedMarkType.Categories[0] && selectedMarkType.Categories[0].MarkTypeCategories && selectedMarkType.Categories[0].MarkTypeCategories.periodo &&
                            <h5>
                              Periodo: <strong>{selectedMarkType.Categories[0].MarkTypeCategories.periodo}</strong>
                            </h5>
                          }
                          {selectedMarkType.Categories && selectedMarkType.Categories.length > 0 && selectedMarkType.Categories[0] && selectedMarkType.Categories[0].MarkTypeCategories && selectedMarkType.Categories[0].MarkTypeCategories.sosta &&
                            <h5>
                              Sosta: <strong>{selectedMarkType.Categories[0].MarkTypeCategories.sosta}</strong>
                            </h5>
                          }
                          <h5>
                            Targ{selectedVehicles.length > 1 ? 'he' : 'a'}: <strong>{selectedVehicles.map(item => item.plate_number + ' ')}</strong>
                          </h5>
                          {getMarkExpirationDate(settings, selectedMarkType, selectedStartDate) &&
                            <h5>
                              Valido fino al: <strong>{getMarkExpirationDate(settings, selectedMarkType, selectedStartDate).format("L")}</strong>
                            </h5>
                          }
                        </div>
                      </div>
                    </div>

                    <div className="col-md-6">
                      <h3>Totale: € {Number(price.price).toLocaleString('it-IT', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</h3>
                      {price.reasons && price.reasons.length > 0 ? price.reasons.map((item, index) => {
                        return (
                          <h5 className="text-info" key={'reasons-' + index}>{item}</h5>
                        )
                      }) : null}
                    </div>
                  </fieldset>

                  {submitting && <div className="box text-info">Richiesta in corso...</div>}

                  <button type="submit" className="btn mainBtn">
                    Richiedi Permesso
                  </button>
                </div>
              }

              <Element name="errors" />
              {errors &&
                <div>
                  <br /><br />
                  <h4 className="box text-danger">{errors}</h4>
                  <br /><br />
                </div>}

            </div>
          </div>
        </div>

        {/* MODALE AGGIUNTA NUOVO VEICOLO */}

        {selectedMarkType &&
          <NewVehicleModal
            show={showNewVehicleModal}
            onAddVehicle={this.onAddVehicle}
            onClose={this.onCloseNewVehicleModal}
            type={selectedMarkType.type}
          />
        }

      </form>
    )
  }
}

export default reduxForm({ form: 'markRequest', enableReinitialize: true })(MarkRequest)
